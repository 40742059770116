import { LoadingScreen, useAppBridgeRouter } from '@segunosoftware/equinox';
import { Loading, NavigationMenu, Provider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import OAuth from './components/OAuth';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import { useHostParam } from './hooks/useHostParam';
import {
	CreatePopup,
	Dashboard,
	EmailMarketingDashboard,
	EmbeddedPopupsList,
	PopupDetails,
	PopupEditor,
	PopupsList,
	Reports,
	ReviewsDashboard,
	SuspendedAccount
} from './utils/lazy-load';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

const NAVIGATION_LINKS = [
	{ label: 'Popups', destination: '/popups' },
	{ label: 'Forms', destination: '/forms' },
	{ label: 'Reports', destination: '/reports' },
	{ label: 'Email Marketing', destination: '/email' },
	{ label: 'Product Reviews', destination: '/reviews' }
];

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<Provider config={config} router={router}>
				<NavigationMenu navigationLinks={NAVIGATION_LINKS} />
				<SentryWrapper>
					<Routes>
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
					</Routes>
				</SentryWrapper>
			</Provider>
		</AppProvider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="popups/*">
						<Route path="new" element={<CreatePopup />} />
						<Route path=":id/editor" element={<PopupEditor />} />
						<Route path=":id" element={<PopupDetails />} />
						<Route path="" element={<PopupsList />} />
					</Route>
					<Route path="forms/*">
						<Route path="new" element={<CreatePopup embedded />} />
						<Route path=":id/editor" element={<PopupEditor />} />
						<Route path=":id" element={<PopupDetails />} />
						<Route path="" element={<EmbeddedPopupsList />} />
					</Route>
					<Route path="reports" element={<Reports />} />
					<Route path="email" element={<EmailMarketingDashboard />} />
					<Route path="reviews" element={<ReviewsDashboard />} />
					<Route path="suspended" element={<SuspendedAccount />} />
					<Route path="" element={<Dashboard />} />
				</Routes>
			</Suspense>
			<SupportChat />
			{calculatedLoading && <Loading />}
		</>
	);
}
