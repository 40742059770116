import { isBlank } from '@segunosoftware/equinox';
import { createContext, useContext, type ProviderProps } from 'react';

const APIContext = createContext('');

export function useAPI() {
	const context = useContext(APIContext);
	if (isBlank(context)) {
		throw new Error('useAPI must be used within a APIProvider');
	}
	return context;
}

export type APIProviderProps = Pick<ProviderProps<string>, 'children'> & { baseUrl: string };

export default function APIProvider({ baseUrl = '', children }: APIProviderProps) {
	return <APIContext.Provider value={baseUrl}>{children}</APIContext.Provider>;
}
