import { isNotBlank, useQueryString } from '@segunosoftware/equinox';
import type { ParsedQuery } from 'query-string';
import { useEffect, useState } from 'react';

function isValidParam(param: unknown): param is string {
	return typeof param === 'string' && isNotBlank(param);
}

function getHostParameter(parsedQueryString: ParsedQuery<string>): string | undefined {
	if (isValidParam(parsedQueryString.host)) {
		return parsedQueryString.host;
	}

	const shop = parsedQueryString.shop || parsedQueryString.state;
	return isValidParam(shop) ? btoa(`${shop}/admin`) : undefined;
}

export function useHostParam() {
	const parsed = useQueryString();
	const hostParam = getHostParameter(parsed);
	const [host, setHost] = useState(hostParam);

	useEffect(() => {
		if (hostParam) {
			setHost(hostParam);
		}
	}, [hostParam]);

	return host;
}
