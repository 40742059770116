import { lazyWithReload as lazy } from '@segunosoftware/equinox';

export const OAuth = lazy(() => import('../components/OAuth'));

export const Dashboard = lazy(() => import('../components/dashboard/Dashboard'));
export const EmailMarketingDashboard = lazy(() => import('../components/email/EmailMarketingDashboard'));
export const ReviewsDashboard = lazy(() => import('../components/reviews/ReviewsDashboard'));
export const Reports = lazy(() => import('../components/reports/Reports'));
export const SuspendedAccount = lazy(() => import('../components/SuspendedAccount'));

export const CreatePopup = lazy(() => import('../components/popups/CreatePopup'));
export const PopupDetails = lazy(() => import('../components/popups/details/PopupDetails'));
export const PopupEditor = lazy(() => import('../components/popups/editor/PopupEditor'));
export const PopupsList = lazy(() => import('../components/popups/PopupsList'));
export const EmbeddedPopupsList = lazy(() => import('../components/popups/EmbeddedPopupsList'));
