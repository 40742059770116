import { LoadingScreen, useQueryString } from '@segunosoftware/equinox';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect as ShopifyRedirect } from '@shopify/app-bridge/actions';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import type { Get } from '../hooks/types';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';

type RedirectUrlResponse = {
	redirectUrl: string;
};

export default function OAuth() {
	return (
		<Routes>
			<Route path="shopify/install" element={<ShopifyInstallComponent />} />
			<Route path="shopify/callback" element={<ShopifyCallbackComponent />} />
			<Route path="shopify/billing/initiate" element={<ShopifyBillingInstallComponent />} />
			<Route path="shopify/billing/callback" element={<ShopifyBillingCallbackComponent />} />
		</Routes>
	);
}

function RedirectComponent({ path }: { path: string }) {
	const app = useAppBridge();
	const params = useQueryString();
	const { get } = useAuthenticatedFetch(false) as Get<RedirectUrlResponse>;
	const [performMutation, setPerformMutation] = useState(false);
	const { mutate, data } = useMutation(() => get(`${path}?${queryString.stringify(params)}`));
	const [redirected, setRedirected] = useState(false);

	const redirectUrl = data?.redirectUrl;

	useEffect(() => {
		setPerformMutation(true);
	}, []);

	useEffect(() => {
		if (performMutation) {
			mutate();
		}
	}, [performMutation, mutate]);

	useEffect(() => {
		if (!redirected && redirectUrl && redirectUrl.indexOf('/') !== 0) {
			setRedirected(true);
			if (isShopifyEmbedded()) {
				const redirect = ShopifyRedirect.create(app);
				redirect.dispatch(ShopifyRedirect.Action.REMOTE, redirectUrl);
			} else if (window?.top) {
				window.top.location = redirectUrl;
			}
		}
	}, [redirected, redirectUrl, app]);

	if (redirectUrl && redirectUrl.indexOf('/') === 0) {
		return <Navigate to={redirectUrl} />;
	}

	return <LoadingScreen>Connecting to Shopify...</LoadingScreen>;
}

function ShopifyInstallComponent() {
	const params = useQueryString();
	if (!params.host && !params.shop) {
		// This workaround is for a Shopify App Bridge bug where they don't pass us the auth query parameters
		return <Navigate to="/" />;
	}
	return <RedirectComponent path="/oauth/shopify/install" />;
}

function ShopifyCallbackComponent() {
	return <RedirectComponent path="/oauth/shopify/callback" />;
}

function ShopifyBillingInstallComponent() {
	return <RedirectComponent path="/oauth/shopify/billing/initiate" />;
}

function ShopifyBillingCallbackComponent() {
	return <RedirectComponent path="/oauth/shopify/billing/callback" />;
}
