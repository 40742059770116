import { getDateFromTimestamp, queryKey, type Merge, type Optional, type Timestamp } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ACCOUNT } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';

export type DismissedContent = {
	onboardingDismissed: boolean;
	suiteCardDismissed: boolean;
};

export type PostscriptPropertyNameMapping = {
	firstName?: string;
	lastName?: string;
	birthdate?: string;
};

export type Account = {
	id: number;
	shop: string;
	domain: string;
	timezone: string;
	currency: string;
	primaryLocale: string;
	platformPlan: string;
	ownerName: string;
	ownerEmail: string;
	userHash: string;
	supportUserHash: string;
	phone: string;
	name: string;
	billingStatus: AccountBillingStatus;
	reviewRating: number;
	reviewStatus: ReviewStatus;
	storefrontProtected: boolean;
	dismissedContent: DismissedContent;
	paidFeaturesAvailable: boolean;
	paidPlan: boolean;
	freeWithSeguno: boolean;
	currentlyPaying: boolean;
	suspended: boolean;
	trialCompleted: boolean;
	trialDays: number;
	upgradedEarly: boolean;
	basePrice: number;
	cappedAmount: number;
	pricePerUnit: number;
	unitAmount: number;
	freeIncludedUnits: number;
	includedUnits: number;
	includedUnitsForPlan: number;
	chargeablePlan: boolean;
	brandingHideable: boolean;
	createdAt: Date;
	updatedAt: Date;
	trialEndsAt?: Date;
	shopCreatedAt?: Date;
	postscriptAccessToken?: string;
	postscriptConnected: boolean;
	postscriptDefaultKeywordId?: string;
	postscriptPropertyNameMapping: PostscriptPropertyNameMapping;
};

export type DehydratedAccount = Merge<
	Account,
	{
		createdAt: Timestamp;
		updatedAt: Timestamp;
		trialEndsAt?: Timestamp;
		shopCreatedAt?: Timestamp;
	}
>;

const hydrateAccount = (account?: DehydratedAccount): Optional<Account> => {
	return account
		? {
				...account,
				createdAt: new Date(account.createdAt),
				updatedAt: new Date(account.updatedAt),
				trialEndsAt: getDateFromTimestamp(account.trialEndsAt),
				shopCreatedAt: getDateFromTimestamp(account.shopCreatedAt)
			}
		: undefined;
};

export function useAccountFetch(load = false) {
	const { get } = useAuthenticatedFetch() as Get<DehydratedAccount>;
	const {
		data: account,
		refetch: fetchAccount,
		isFetching: isLoading
	} = useQuery(queryKey(ACCOUNT), () => get('/auth/whoami').then(hydrateAccount), { enabled: load });
	return { account, fetchAccount, isLoading };
}

export function useAccount() {
	const { account } = useAccountFetch();

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useSetAccount() {
	const client = useQueryClient();
	const setAccount = useCallback((account: DehydratedAccount) => client.setQueryData(queryKey(ACCOUNT), hydrateAccount(account)), [client]);
	return setAccount;
}

export function useReviewRating() {
	const { post } = useAuthenticatedFetch() as Post<any, DehydratedAccount>;
	const setAccount = useSetAccount();
	const setAccountOption = { onSuccess: setAccount };

	const { mutate: setReviewRating, isLoading: isSetReviewRatingLoading } = useMutation(
		(reviewRating: number) => post('/account/review-rating', { rating: reviewRating }),
		setAccountOption
	);

	const { mutate: setReviewStatus, isLoading: isSetReviewStatusLoading } = useMutation(
		(reviewStatus: ReviewStatus) => post('/account/review-status', { reviewStatus }),
		setAccountOption
	);

	return {
		setReviewRating: (reviewRating: number) => setReviewRating(reviewRating),
		isSetReviewRatingLoading,
		setReviewStatus: (reviewStatus: ReviewStatus) => setReviewStatus(reviewStatus),
		isSetReviewStatusLoading
	};
}
