import { LoadingScreen } from '@segunosoftware/equinox';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountFetch } from '../hooks/useAccount';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const { account } = useAccountFetch(true);
	const location = useLocation();

	if (!account) {
		return <LoadingScreen />;
	}

	const isSuspendedPath = location.pathname.indexOf('/suspended') === 0;
	if (account.suspended && !isSuspendedPath) {
		return <Navigate to="/suspended" />;
	} else if (!account.suspended && isSuspendedPath) {
		return <Navigate to="/" />;
	}

	return element;
}
