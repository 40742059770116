import download from 'downloadjs';

export default class HttpClient {
	constructor(options = {}) {
		this.baseURL = options.baseURL || '';
		this.headers = options.headers || {};
	}

	async performFetch(endpoint, options = {}, headers = {}) {
		const computedHeaders = { ...this.headers, ...headers };
		const opts = {
			mode: 'cors',
			method: options.method || 'GET',
			...options
		};
		computedHeaders['Accept'] = computedHeaders['Accept'] || 'application/json';
		if (opts.body) {
			if (!(opts.body instanceof FormData)) {
				computedHeaders['Content-Type'] = computedHeaders['Content-Type'] || 'application/json';
			}
			if (computedHeaders['Content-Type'] === 'application/json' && typeof opts.body !== 'string') {
				opts.body = JSON.stringify(opts.body);
			}
		}
		const controller = new AbortController();
		const promise = fetch(this.baseURL + endpoint, {
			...opts,
			headers: computedHeaders,
			signal: controller.signal
		});
		promise.cancel = () => controller.abort();

		const response = await promise;

		if (!response.ok) {
			if (options.parseResponse !== false) {
				let parsedResponse = null;
				try {
					parsedResponse = await response.json();
				} catch (e) {
					// Ignore, the parsing didn't work
				}
				if (parsedResponse) {
					// eslint-disable-next-line no-throw-literal
					throw {
						headers: response.headers,
						ok: response.ok,
						status: response.status,
						statusText: response.statusText,
						url: response.url,
						body: parsedResponse
					};
				}
			}
			throw response;
		}

		if (options.download) {
			return response.blob().then(blob => {
				download(blob, options.filename);
				return response;
			});
		}

		if (options.parseResponse !== false && response.status !== 204) {
			return response.json();
		}

		return undefined;
	}

	setHeader(key, value) {
		this.headers[key] = value;
		return this;
	}

	getHeader(key) {
		return this.headers[key];
	}

	setBasicAuth(username, password) {
		this.headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
		return this;
	}

	setBearerAuth(token) {
		this.headers.Authorization = `Bearer ${token}`;
		return this;
	}

	async get(endpoint, options = {}, headers = {}) {
		return this.performFetch(
			endpoint,
			{
				...options,
				method: 'GET'
			},
			headers
		);
	}

	async post(endpoint, body, options = {}, headers = {}) {
		return this.performFetch(
			endpoint,
			{
				...options,
				body,
				method: 'POST'
			},
			headers
		);
	}

	async put(endpoint, body, options = {}, headers = {}) {
		return this.performFetch(
			endpoint,
			{
				...options,
				body,
				method: 'PUT'
			},
			headers
		);
	}

	async patch(endpoint, body, options = {}, headers = {}) {
		return this.performFetch(
			endpoint,
			{
				...options,
				body,
				method: 'PATCH'
			},
			headers
		);
	}

	async delete(endpoint, options = {}, headers = {}) {
		return this.performFetch(
			endpoint,
			{
				parseResponse: false,
				...options,
				method: 'DELETE'
			},
			headers
		);
	}
}
