import { useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import { useEffect, useMemo, useState } from 'react';
import { useAPI } from '../APIProvider';
import HttpClient from '../lib/HttpClient';

const SUPPORTED_FUNCTIONS = ['get', 'post', 'put', 'patch', 'delete'];

export function useAuthenticatedFetch(authenticated = true) {
	const app = useAppBridge();
	const api = useAPI();
	const [httpClientError, setHttpClientError] = useState();

	useEffect(() => {
		if (httpClientError) {
			setHttpClientError(null);
			throw httpClientError;
		}
	}, [httpClientError]);

	const fetchFunctions = useMemo(() => {
		const client = new HttpClient({
			baseURL: api
		});
		return SUPPORTED_FUNCTIONS.reduce((acc, curr) => {
			acc[curr] = async (...args) => {
				if (authenticated) {
					const sessionToken = await getSessionToken(app);
					client.setBearerAuth(sessionToken);
				}

				return client[curr](...args).catch(error => {
					if (error instanceof Response && Number(error.status) >= 500) {
						setHttpClientError({
							error,
							message: `HTTP error from ${curr} method`,
							args: { ...args }
						});
					}

					throw error; // In every case; a false success would be bad, though I'm not sure it would happen anyway.
				});
			};
			return acc;
		}, {});
	}, [api, app, authenticated]);

	return {
		...fetchFunctions
	};
}
