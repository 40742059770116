import type { LinkLikeComponentProps } from '@shopify/polaris/build/ts/src/utilities/link';
import React, { type Ref } from 'react';
import { Link } from 'react-router-dom';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

const CustomLinkComponent = React.forwardRef(
	({ url = '', children, external, ...rest }: LinkLikeComponentProps, ref: Ref<HTMLAnchorElement>) => {
		if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
			rest.target = '_blank';
			rest.rel = 'noopener noreferrer';
			return (
				<a href={url} ref={ref} {...rest}>
					{children}
				</a>
			);
		}

		return (
			<Link to={url} ref={ref} {...rest}>
				{children}
			</Link>
		);
	}
);

export default CustomLinkComponent;
